/* =======================
Active Nav
========================*/
var path = location.pathname.substring(1);


// Fix store locator showing nutrition as selected
if(path.split('/')[1]) {
  $(`nav a[href$="/${path.split('/')[1]}"]`).parent().addClass('selected');
  $(`nav a[href^="/${path.split('/')[0]}"]`).closest('.has-dropdown').addClass('selected');
} else {
  $(`nav a[href="/${path.split('/')[0]}"]`).parent().addClass('selected');
  $(`nav a[href^="/${path.split('/')[0]}"]`).closest('.has-dropdown').addClass('selected');
}

if(!path.split('/')[0]) {
  $('nav .selected').removeClass('selected');
  $(`nav a[href="/"]`).parent().addClass('selected');
}
if(path) {
  $(`nav a[href$="/${path}"]`).parentsUntil('.has-dropdown').addClass('selected');
} else {
  $(`nav a[href$="${path}"]`).parents('li').addClass('selected');
}


/* ==================================================
Toggle element:

Toggles animated display of element with an id that
matches the 'data-toggle-trigger' of the clicked
element. The default animation is slide in and
slide out.
===================================================*/

$('a[data-toggle-trigger]').click(function (e) {
  e.preventDefault();
  const triggerId = $(this).data('toggle-trigger');
  const toggleItem = $(`#${triggerId}`);

  $(this).toggleClass('open');

  const $toggleElement = new Foundation.Toggler(toggleItem, {
    animate: 'animate-in animate-out',
  });

  toggleItem.foundation('toggle');
});

/* ==================================================
Smooth Scrolling :

Smooth Scrolling Jquery Plugin
===================================================*/

// Select all links with hashes
$('a[href^="#"]:not(.nav-label, .dropdown-arrow)').on('click',function (e) {
    e.preventDefault();

    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
        window.location.hash = target;
    });
});

//Utilities
$(function(){
  $('html').removeClass('no-js');
});


/* ==================================================
Fancybox galleries:
===================================================*/
$("[data-fancybox]").fancybox({
    margin : [44, 0],
    //gutter : 300,
    arrows : true,
    buttons : [
        'slideShow',
        'fullScreen',
        //'thumbs',
        'share',
        //'download',
        'zoom',
        'close'
    ],
});


/* ==================================================
Toggle mobile nav dropdown:

Toggle mobile nav dropdown when clicked
===================================================*/
$('.main-nav-small .has-dropdown .dropdown-arrow').click(function (e) {
  e.preventDefault();
  $(this).toggleClass('open');
  $(this).parents().next('.dropdown').slideToggle();
})


/* ==================================================
Alpine components
===================================================*/
document.addEventListener('alpine:init', () => {
  window.Alpine = Alpine;

  Alpine.store('sideNav', {
    open: false,

    close() {
      this.open = false;
    },

    toggle() {
      this.open = !this.open;

      if(this.open) {
        document.body.classList.add('offscreen-nav-visible');
        return;
      }
      document.body.classList.remove('offscreen-nav-visible');
    }
  });

  Alpine.data('sideNav', () => ({
    init() {
      this.currentLocation = window.location.pathname;
    },
    active: null,
    currentLocation: ''
  }));

  Alpine.data('sideNavDropdown', (id= '') => ({
    get expanded() {
      return this.active === id;
    },
    set expanded(value) {
      this.active = value ? id : null
    }
  }));


});